import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery, Link } from "gatsby";
import ImageTag from "gatsby-image";
import * as styles from "./header.module.css";

const Header = (props) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(name: { eq: "waivpay-logo" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <header className={styles.container}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-center md:justify-between items-center border-b-2 border-gray-100 py-6 md:jusify-start md:space-x-10">
          <div className="flex justify-center lg:w-0 lg:flex-1">
            <Link to="/">
              <ImageTag Tag="div" className="mx-auto w-48" fluid={logo.sharp.fluid} />
            </Link>
          </div>
          <nav className="hidden md:flex space-x-10">
            <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900">Products</Link>
            <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900">Solutions</Link>
            <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900">Support</Link>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {
              !props.withoutBalanceCheck &&
                <Link to="/balance-check" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary bg-opacity-80 hover:bg-opacity-70 sm:px-8">
                  Balance Check
                </Link>
            }
          </div>
        </div>
      </div>
    </header>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
